import {WithTranslation, withTranslation} from 'react-i18next'
import {Elements} from '@stripe/react-stripe-js'
import {Appearance, loadStripe} from '@stripe/stripe-js'
import {StripeElementsOptions} from '@stripe/stripe-js'

import CheckoutForm from './CheckoutForm/CheckoutForm'

import styles from './Stripe.module.scss'

// secret
// pi_3N8YtyI3DxpZq8Uj0WT47YSZ_secret_mW50cMfq8u42kIItMgN9jSq6
// pk
// pk_test_51MrieYI3DxpZq8UjL0cBlqSNpHQPvXH17zdxItQOeTKU11Sd2hhaeRatbPH9SjbiZkBbHGXEbbWpGiatEHQrg9oC004OCskRYU

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.
const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_API_KEY ||
    'pk_test_51MrieYI3DxpZq8UjL0cBlqSNpHQPvXH17zdxItQOeTKU11Sd2hhaeRatbPH9SjbiZkBbHGXEbbWpGiatEHQrg9oC004OCskRYU',
)

interface IStripe extends WithTranslation {
  clientSecret: string
  onSuccess: () => void
}

const Stripe = ({clientSecret, onSuccess, t}: IStripe) => {
  const appearance: Appearance = {
    theme: 'stripe',
  }
  const options: StripeElementsOptions = {
    clientSecret,
    appearance,
  }

  return (
    <>
      <div className={styles.title}>{t('stripe.title')}</div>
      <div className={styles.stripe}>
        {clientSecret && (
          <Elements options={options} stripe={stripePromise}>
            <CheckoutForm onSuccess={onSuccess} />
          </Elements>
        )}
      </div>
    </>
  )
}

export default withTranslation()(Stripe)
