import {WithTranslation, withTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'

import Button from '../../../../../../components/Button/Button'
import {PATHS} from '../../../../../../constants/paths'
import BannerContainer from '../../../../../../containers/BannerContainer'
import BannerSection from '../../../../../../containers/BannerSection'
import {useStepperSlice} from '../../../../../../store/slices/stepper'

import styles from './BinancePay.module.scss'

const BinancePay = ({t}: WithTranslation) => {
  const {activeItemId, nextStep} = useStepperSlice()
  const navigate = useNavigate()
  return (
    <BannerSection className={styles.section}>
      <BannerContainer className={styles.container} layout="column">
        <div className={styles.title}>Termina tu pago</div>
        <div className={styles.stripe}>
          <span>Binance Pay</span>
          <span>Coming soon</span>
          <span>Item id: {activeItemId}</span>
        </div>
        <Button
          className={styles.button}
          styledType="filled"
          filledColor="primary"
          onClick={() => {
            nextStep()
            navigate(`${PATHS.INVESTMENT}/${activeItemId}`)
          }}>
          {t('continue')}
        </Button>
      </BannerContainer>
    </BannerSection>
  )
}

export default withTranslation()(BinancePay)
