import axios from 'axios'

import Config from '../utils/config'

axios.defaults.baseURL = Config.baseUrlApi

axios.defaults.headers.common['Authorization'] = `${
  !!localStorage.getItem('token') ? `Token ${localStorage.getItem('token')}` : ''
}`

export default axios
