import {useTranslation} from 'react-i18next'
import classNames from 'classnames'

import {actionModel} from '../../ActionList'

import styles from './ActionCard.module.scss'

interface IItemCardProps {
  className?: string
  actionData: actionModel
}

const ActionCard = ({className, actionData}: IItemCardProps) => {
  const {t} = useTranslation()
  return (
    <div className={classNames(styles.card, className)}>
      {actionData.icon}
      <span className={styles.action}>{t(actionData.actionKey)}</span>
      <span className={styles.description}>{t(actionData.descriptionKey)}</span>
    </div>
  )
}

export default ActionCard
