import {useMemo} from 'react'
import {GoogleMap, Marker, useLoadScript} from '@react-google-maps/api'

import styles from './Map.module.scss'
interface IMapProps {
  center?:
    | {
        lat: number
        lng: number
      }
    | undefined
  defaultZoom?: number | undefined
}

const Map = ({center, defaultZoom}: IMapProps) => {
  const {isLoaded} = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY || '',
  })
  const defaultProps = useMemo(() => {
    return {
      center: center || {
        lat: 43.462912,
        lng: -3.800233,
      },
      zoom: defaultZoom || 16,
    }
  }, [])

  return (
    <>
      {isLoaded && (
        <GoogleMap
          center={defaultProps.center}
          zoom={defaultProps.zoom}
          mapContainerClassName={styles.container}
          options={{
            streetViewControl: true,
            streetViewControlOptions: {
              position: 3,
            },
            zoomControlOptions: {
              position: 6,
            },
            // mapTypeControlOptions: {
            //   style: 3,
            // },
          }}>
          <Marker position={defaultProps.center} />
        </GoogleMap>
      )}
    </>
  )
}
export default Map
