import {SubStep} from '../../pages/investment/steps/PaymentConfirmation/PaymentConfirmation'
import {PaymentMethod} from '../../services/interfaces/IToken'
import myLocalStorage from '../../utils/myLocalStorage'

export const INVESTMENT_LOCALSTORAGE_KEY = 'InvestmentState'

export interface IInvestmentLocalStorage {
  projectId?: number
  amountOfTokens?: number
  paymentMethodTypeSelected?: PaymentMethod
  activeStepNumber?: number
  activeSubStep?: SubStep
  paymentIntentClientSecret?: string
  isPaymentSuccessful?: boolean
}

export const getInvestmentStorage = (): IInvestmentLocalStorage => {
  return {...myLocalStorage.get(INVESTMENT_LOCALSTORAGE_KEY)} as IInvestmentLocalStorage
}

export const saveInvestmentStorage = (investmentData: IInvestmentLocalStorage) => {
  myLocalStorage.set(INVESTMENT_LOCALSTORAGE_KEY, {
    ...getInvestmentStorage(),
    ...investmentData,
  })
}

export const clearInvestmentStorage = () => myLocalStorage.remove(INVESTMENT_LOCALSTORAGE_KEY)
