import Skeleton from 'react-loading-skeleton'
import classNames from 'classnames'

import useBreakpoint from '../../../hooks/useBreakpoint'
import Card from '../../Card/Card'
import ItemPropertySkeleton from '../components/ItemProperty/skeleton/ItemPropertySkeleton'

import styles from '../ItemCard.module.scss'

interface IItemCardSkeleton {
  className?: string
  layout?: 'row' | 'column'
  design?: 'first' | 'second'
}

const ItemCardSkeleton = ({className, layout = 'row', design = 'first'}: IItemCardSkeleton) => {
  const breakpoint = useBreakpoint()
  return (
    <Card className={className}>
      <div className={classNames(styles.card, styles[layout], styles[design])}>
        <div className={styles.info}>
          <div className={styles.headerInfo}>
            <div className={styles.name}>
              <Skeleton width="70%" />
            </div>
            <div className={styles.location}>
              <Skeleton width="60%" />
            </div>
          </div>
          <div className={styles.propertiesContainer}>
            <ItemPropertySkeleton />
            <ItemPropertySkeleton />
            <ItemPropertySkeleton />
          </div>
          <div className={styles.button} style={{textAlign: 'center'}}>
            <Skeleton
              width="80%"
              height={40}
              style={{borderRadius: design === 'first' ? '6.25rem' : '0'}}
            />
          </div>
        </div>
        <div className={classNames(styles.itemImage, styles[design])}>
          <Skeleton
            height={
              breakpoint === 's' || breakpoint === 'xs' || layout === 'column' ? '200px' : '100%'
            }
          />
        </div>
      </div>
    </Card>
  )
}

export default ItemCardSkeleton
