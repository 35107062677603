import Skeleton from 'react-loading-skeleton'
import classNames from 'classnames'

import styles from '../ItemProperty.module.scss'

interface IItemPropertySkeleton {
  oneLine?: boolean
}

const ItemPropertySkeleton = ({oneLine}: IItemPropertySkeleton) => (
  <div className={classNames(styles.propertyRow, oneLine && styles.oneLine)}>
    <div className={styles.circle}>
      <Skeleton width="100%" height="100%" circle />
    </div>
    <div className={styles.propertyContainer}>
      <div className={styles.value}>
        <Skeleton width={100} height={20} />
      </div>
      <div className={styles.label}>
        <Skeleton width={120} />
      </div>
    </div>
  </div>
)

export default ItemPropertySkeleton
