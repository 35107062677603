import {create, StateCreator} from 'zustand'

import {CurrencyType} from '../../constants/currencies'
import {rateApi} from '../../services'
import {IRate} from '../../services/api/rate'

export interface RatesSlice {
  rates: IRate[]
  fetchRates: (currency?: CurrencyType) => void
}

const createRatesSlice: StateCreator<RatesSlice> = set => ({
  rates: [],
  fetchRates: async (currency?: CurrencyType) => {
    try {
      const ratesData = await rateApi.getRates(currency)
      set(() => ({rates: ratesData}))
    } catch (error) {
      console.log('Error fetching rates')
    }
  },
})

export const useRatesSlice = create<RatesSlice>()((...a) => ({
  ...createRatesSlice(...a),
}))
