import classNames from 'classnames'

import styles from './PercentageCircle.module.scss'
interface IPercentageCircleProps {
  className?: string
  percentage: number
  size?: number
  borderColor?: 'primary' | 'secondary'
}
const PercentageCircle = ({
  className,
  percentage,
  borderColor = 'secondary',
}: IPercentageCircleProps) => (
  <div className={classNames(styles.wrapper, className)}>
    <div className={styles.singleChart}>
      <svg viewBox="0 0 36 36" className={classNames(styles.circularChart, styles[borderColor])}>
        <path
          className={styles.circleBg}
          d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
        />
        <path
          className={classNames(styles.circle, {
            [styles.empty]: percentage === 0,
            [styles.completed]: percentage === 100,
          })}
          strokeDasharray={`${percentage}, 100`}
          d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
        />
        <text
          x="18"
          y="21.35"
          className={classNames(styles.percentage, {[styles.completed]: percentage === 100})}>
          {percentage}%
        </text>
      </svg>
    </div>
  </div>
)

export default PercentageCircle
