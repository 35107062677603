import {withTranslation} from 'react-i18next'

import BannerContainer from '../../containers/BannerContainer'
import BannerSection from '../../containers/BannerSection'

import RecentTransactionsMaximized from './components/RecentTransactionsMaximized/RecentTransactionsMaximized'
import RecentTransactionsMini from './components/RecentTransactionsMini/RecentTransactionsMini'

import styles from './RecentTransactions.module.scss'

interface IRecentTransactions {
  showReduced?: boolean
}

const RecentTransactions = ({showReduced}: IRecentTransactions) => {
  return (
    <BannerSection className={styles.section}>
      <BannerContainer className={styles.container} layout="column">
        {showReduced ? <RecentTransactionsMini /> : <RecentTransactionsMaximized />}
      </BannerContainer>
    </BannerSection>
  )
}
export default withTranslation()(RecentTransactions)
