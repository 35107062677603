import {initReactI18next} from 'react-i18next'
import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import en from '../translations/en/translation.json'
import es from '../translations/es/translation.json'

import {BRAND} from './brand'

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: {
      en: {
        translations: {
          ...en,
          ...require('src/brands/' + BRAND + '/translations/en/translation.json'),
        },
      },
      es: {
        translations: {
          ...es,
          ...require('src/brands/' + BRAND + '/translations/es/translation.json'),
        },
      },
    },
    fallbackLng: 'es',
    lng: 'es',

    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',

    interpolation: {
      escapeValue: false,
    },
  })

export default i18n
