import {useState} from 'react'
import {WithTranslation, withTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'

import {ReactComponent as Success} from '../../assets/icons/success.svg'
import Button from '../../components/Button/Button'
import Modal from '../../components/Modal/Modal'
import {currentCurrency} from '../../constants/currencies'
import {PATHS} from '../../constants/paths'
import BannerContainer from '../../containers/BannerContainer'
import BannerSection from '../../containers/BannerSection'
import {useStepperSlice} from '../../store/slices/stepper'

import styles from './AddFunds.module.scss'

const AddFunds = ({t}: WithTranslation) => {
  const {activeItemId, nextStep} = useStepperSlice()
  const navigate = useNavigate()
  const [fundedAccountSuccess, showFundedAccountSuccess] = useState<boolean>(false)
  return (
    <>
      <BannerSection className={styles.section}>
        <BannerContainer className={styles.container} layout="column">
          <div className={styles.title}>Agregar fondos a tu billetera</div>
          <div className={styles.onRamper}>
            <span>On Ramper</span>
            <span>Coming soon</span>
            <span>Item id: {activeItemId}</span>
          </div>
          <Button
            className={styles.button}
            styledType="filled"
            filledColor="primary"
            onClick={() => {
              nextStep()
              showFundedAccountSuccess(true)
            }}>
            {t('continue')}
          </Button>
        </BannerContainer>
      </BannerSection>

      <Modal
        visible={fundedAccountSuccess}
        onClose={() => showFundedAccountSuccess(false)}
        closeButtonHidden
        containerClassName={styles.fundsModal}
        icon={<Success />}
        title={'Fondeaste tu cuenta correctamente'}
        button={{
          label: t('continue'),
          onClick: () => navigate(`${PATHS.INVESTMENT}/${activeItemId}`),
        }}>
        <div className={styles.price}>
          <div className={styles.label}>Compraste</div>
          <span>
            <div className={styles.value}>1050</div>
            <div className={styles.currency}>usdt</div>
          </span>
          <div className={styles.officialCurrency}>≈ 1000 {currentCurrency}</div>
        </div>
      </Modal>
    </>
  )
}

export default withTranslation()(AddFunds)
