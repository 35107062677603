import {create, StateCreator} from 'zustand'

export const TOKEN_AMOUNT_INDEX = 'tokenAmount'
export type CounterIndexType = typeof TOKEN_AMOUNT_INDEX
export interface CounterSlice {
  quantity: {[key: string]: number}
  maximumQuantity: {[key: string]: number}
  setQuantity: (nameIndex: string, newQuantity: number) => void
  setMaximumQuantity: (nameIndex: string, newMaximumQuantity: number) => void
  substract: (nameIndex: string) => void
  add: (nameIndex: string) => void
}

export const createCounterSlice: StateCreator<CounterSlice, [], [], CounterSlice> = set => ({
  quantity: {},
  maximumQuantity: {},
  setQuantity: (nameIndex, newQuantity) =>
    set(state => ({
      quantity: {
        ...state.quantity,
        [nameIndex]: newQuantity,
      },
    })),
  setMaximumQuantity: (nameIndex, newMaximumQuantity) =>
    set(({maximumQuantity}) => ({
      maximumQuantity: {...maximumQuantity, [nameIndex]: newMaximumQuantity},
    })),
  add: nameIndex =>
    set(({quantity, maximumQuantity}) => ({
      quantity: {
        ...quantity,
        [nameIndex]:
          quantity[nameIndex] < maximumQuantity[nameIndex]
            ? quantity[nameIndex] + 1
            : quantity[nameIndex],
      },
    })),
  substract: nameIndex =>
    set(({quantity}) => ({
      quantity: {
        ...quantity,
        [nameIndex]: quantity[nameIndex] > 1 ? quantity[nameIndex] - 1 : quantity[nameIndex],
      },
    })),
})

export const useCounterSlice = create<CounterSlice>()((...a) => ({
  ...createCounterSlice(...a),
}))
