import {useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import OutsideClickHandler from 'react-outside-click-handler'
import classNames from 'classnames'

import {ReactComponent as ArrowDown} from '../../assets/icons/arrow-down-purple.svg'
import {ENGLISH_KEY_TRANSLATION, SPANISH_KEY_TRANSLATION} from '../../constants/languages'

import styles from './DropdownLanguage.module.scss'

interface IDropdownLanguageProps {
  label?: string | null
  className?: string
  customClasses?: {
    label?: string
    head?: string
    selection?: string
    arrow?: string
    body?: string
  }
}

const MAX_WIDTH_MOBILE = 767

const DropdownLanguage = ({label, className, customClasses = {}}: IDropdownLanguageProps) => {
  const {t, i18n} = useTranslation()
  const [visible, setVisible] = useState(false)
  const [isMobileWidthResolution, setIsMobileWidthResolution] = useState(
    window.innerWidth <= MAX_WIDTH_MOBILE,
  )

  const languages = useCallback(
    () => [
      {key: 'en', label: t(ENGLISH_KEY_TRANSLATION)},
      {key: 'es', label: t(SPANISH_KEY_TRANSLATION)},
    ],
    [t],
  )

  const languageSelected = languages().find(lang => lang.key === i18n.language) || {
    key: 'en',
    label: t(ENGLISH_KEY_TRANSLATION),
  }

  const handleClick = (option: {key: string; label: string}) => {
    onChangeLanguage(option)
    setVisible(false)
  }

  const onChangeLanguage = (lang: {key: string; label: string}) => i18n.changeLanguage(lang.key)

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= MAX_WIDTH_MOBILE && !isMobileWidthResolution)
        setIsMobileWidthResolution(true)
      else if (window.innerWidth > MAX_WIDTH_MOBILE && isMobileWidthResolution)
        setIsMobileWidthResolution(false)
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [isMobileWidthResolution])

  return (
    <div className={styles.container}>
      {!!label && <div className={classNames(styles.label, customClasses.label)}>{label}</div>}
      <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
        <div className={classNames(styles.dropdown, className, {[styles.active]: visible})}>
          <div
            onKeyDown={() => {}}
            tabIndex={0}
            role="button"
            className={classNames(styles.head, customClasses.head)}
            onClick={() => setVisible(!visible)}>
            <div className={classNames(styles.selection, customClasses.selection)}>
              {isMobileWidthResolution
                ? languageSelected.key.toLowerCase()
                : languageSelected.label}
            </div>
            <div className={classNames(styles.arrow, customClasses.arrow)}>
              <ArrowDown className={styles.image} />
            </div>
          </div>
          <div className={classNames(styles.bodyContainer, customClasses.body)}>
            <div className={styles.body}>
              {languages().map((option, index) => (
                <div
                  onKeyDown={() => {}}
                  tabIndex={0}
                  role="button"
                  className={classNames(styles.option, {
                    [styles.selectioned]: option.key === languageSelected.key,
                  })}
                  onClick={() => handleClick(option)}
                  key={index}>
                  <span className={styles.text}>
                    {isMobileWidthResolution ? option.key : option.label}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </OutsideClickHandler>
    </div>
  )
}

export default DropdownLanguage
