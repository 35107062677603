import {useEffect} from 'react'

import {ReactComponent as Add} from '../../assets/icons/add.svg'
import {ReactComponent as Subtract} from '../../assets/icons/subtract.svg'
import {CounterIndexType, useCounterSlice} from '../../store/slices/counter'

import styles from './Counter.module.scss'

type ICounterProps = {
  initialValue?: number
  maximumValue?: number
  nameIndex: CounterIndexType
  tokenId: number
  readOnly?: boolean
}

const Counter = ({
  initialValue = 1,
  maximumValue = 10,
  nameIndex,
  tokenId,
  readOnly,
}: ICounterProps) => {
  const {quantity, maximumQuantity, setMaximumQuantity, setQuantity, substract, add} =
    useCounterSlice()

  const index = `${nameIndex}${tokenId}`

  useEffect(() => {
    if (!quantity[index] || quantity[index] !== initialValue) {
      setQuantity(index, initialValue)
    }
  }, [initialValue])

  const onChange = (value: number) => {
    if (quantity[index] === 0 && value === 0) return
    if (value <= maximumQuantity[index] && value !== quantity[index]) {
      setQuantity(index, value)
    }
  }

  useEffect(() => {
    if (!maximumQuantity[index] || maximumQuantity[index] !== maximumValue) {
      setMaximumQuantity(index, maximumValue)
    }
  }, [maximumValue])

  return (
    <div className={styles.counter}>
      <Subtract onClick={() => substract(index)} />
      <input
        className={styles.count}
        type="number"
        min={1}
        value={quantity[index]?.toString() || 0}
        max={maximumQuantity[index]}
        onChange={event => onChange(+event.target.value)}
        readOnly={readOnly}
      />
      <Add onClick={() => add(index)} />
    </div>
  )
}

export default Counter
