import axios from '../../core/axios'

export interface IRate {
  id: number
  created_at: string
  modified_at: string
  currency: string
  rate: string
}

export default {
  getRates: async (currency?: string): Promise<IRate[]> => {
    const {
      data: {results},
    } = await axios.get(`rates/${(!!currency && `?currency=${currency}`) || ''}`)
    return results
  },
}
