import {ReactNode, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {ReactComponent as All} from '../../../../assets/icons/all.svg'
import {ReactComponent as Commercial} from '../../../../assets/icons/commercial.svg'
import {ReactComponent as Lands} from '../../../../assets/icons/lands.svg'
import {ReactComponent as Residential} from '../../../../assets/icons/residential.svg'
import {ReactComponent as Vacation} from '../../../../assets/icons/vacation.svg'

interface IFilter {
  icon: ReactNode
  key: string
}

import classNames from 'classnames'

import {TYPOLOGY_TYPE, TypologyType} from '../../../../services/interfaces/IProject'

import styles from './ButtonFilter.module.scss'

interface IButtonFilterProps {
  selectFilter: (filter: TypologyType | 'all') => void
}

const ButtonFilter = ({selectFilter}: IButtonFilterProps) => {
  const {t} = useTranslation()
  const [activeFilter, setActiveFilter] = useState<string>('all')

  const filters: IFilter[] = useMemo<IFilter[]>(
    () =>
      [{key: 'all', icon: <All />}].concat([
        {key: TYPOLOGY_TYPE.RESIDENTIAL, icon: <Residential />},
        {key: TYPOLOGY_TYPE.COMMERCIAL, icon: <Commercial />},
        {key: TYPOLOGY_TYPE.LANDS, icon: <Lands />},
        {key: TYPOLOGY_TYPE.VACATION, icon: <Vacation />},
      ]),
    [t],
  )

  return (
    <div className={styles.row}>
      {filters.map(filter => (
        <div
          key={filter.key}
          className={classNames(styles.button, {[styles.active]: activeFilter === filter.key})}
          onClick={() => {
            setActiveFilter(filter.key)
            selectFilter(filter.key as 'all' | TypologyType)
          }}>
          {filter.icon}
          {t(`item.typology.${filter.key}`)}
        </div>
      ))}
    </div>
  )
}

export default ButtonFilter
