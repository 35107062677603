import React, {useCallback, useEffect, useState} from 'react'
import {WithTranslation, withTranslation} from 'react-i18next'
import {
  LinkAuthenticationElement,
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import {StripePaymentElementOptions} from '@stripe/stripe-js'

import Button from '../../../../../../../components/Button/Button'
import {useCustomerSlice} from '../../../../../../../store/slices/customer'

import styles from './CheckoutForm.module.scss'

const CheckoutForm = ({t}: WithTranslation) => {
  const stripe = useStripe()
  const elements = useElements()
  const {
    customer_info: {email: customerEmail},
  } = useCustomerSlice()

  const [email, setEmail] = useState<string | undefined>(customerEmail)
  const [message, setMessage] = useState<string>()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    if (!stripe) {
      return
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret',
    )

    if (!clientSecret) {
      return
    }

    stripe.retrievePaymentIntent(clientSecret).then(({paymentIntent}: any) => {
      switch (paymentIntent.status) {
        case 'succeeded':
          setMessage('Payment succeeded!')
          break
        case 'processing':
          setMessage('Your payment is processing.')
          break
        case 'requires_payment_method':
          setMessage('Your payment was not successful, please try again.')
          break
        default:
          setMessage('Something went wrong.')
          break
      }
    })
  }, [stripe])

  const handleSubmit = useCallback(
    async (event: any) => {
      event.preventDefault()

      if (!stripe || !elements) {
        // Stripe.js hasn't yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return
      }

      setIsLoading(true)

      const {error} = await stripe.confirmPayment({
        elements,
        confirmParams: {
          // Make sure to change this to your payment completion page
          // return_url: 'http://localhost:3000',
          return_url: window.location.href,
          receipt_email: email,
        },
      })

      // This point will only be reached if there is an immediate error when
      // confirming the payment. Otherwise, your customer will be redirected to
      // your `return_url`. For some payment methods like iDEAL, your customer will
      // be redirected to an intermediate site first to authorize the payment, then
      // redirected to the `return_url`.
      if (error.type === 'card_error' || error.type === 'validation_error') {
        setMessage(error.message)
      } else {
        setMessage('An unexpected error occurred.')
      }

      setIsLoading(false)
    },
    [stripe, elements, isLoading, email],
  )

  const paymentElementOptions: StripePaymentElementOptions = {
    layout: 'tabs',
    terms: {
      card: 'always',
      sepaDebit: 'always',
      // bancontact: 'always',
      // ideal: 'always',
      // sofort: 'always',
      auBecsDebit: 'always',
      usBankAccount: 'always',
    },
  }

  return (
    <form id="payment-form" className={styles.form} onSubmit={handleSubmit}>
      <LinkAuthenticationElement
        id="link-authentication-element"
        onChange={(e: any) => setEmail(e.target?.value)}
      />
      <PaymentElement
        id="payment-element"
        className={styles.paymentElement}
        options={paymentElementOptions}
      />
      {message && (
        <div id="payment-message" className={styles.paymentMessage}>
          {message}
        </div>
      )}
      <Button
        disabled={isLoading || !stripe || !elements}
        id="submit"
        type="submit"
        className={styles.button}
        styledType="filled"
        filledColor="primary"
        onClick={() => {
          // nextStep()
          // navigate(`${PATHS.INVESTMENT}/${activeItemId}`)
        }}>
        <span id="button-text">
          {isLoading ? <div className={styles.spinner} id="spinner"></div> : t('stripe.payNow')}
        </span>
      </Button>
    </form>
  )
}

export default withTranslation()(CheckoutForm)
