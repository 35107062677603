import axios from '../../core/axios'

export enum ONBOARDING_SECTION {
  portfolio = 'portfolio',
}

export type OnBoardingSection = keyof typeof ONBOARDING_SECTION
export interface IOnboardingResponse {
  id: number
  completed: boolean
  customer: number
  onboarding: number
}
export interface IOnBoardingError {
  error: string
}

export default {
  checkOnBoardingCompleted: async (
    section: OnBoardingSection,
  ): Promise<IOnboardingResponse | IOnBoardingError> => {
    try {
      const {data: responseData} = await axios.get(`onboardings/${section}`)
      if (responseData?.error) return responseData as IOnBoardingError
      return responseData as IOnboardingResponse
    } catch (error: any) {
      throw Error(error?.message)
    }
  },
  finishOnBoarding: async (section: OnBoardingSection): Promise<IOnboardingResponse> => {
    try {
      const {data: responseData} = await axios.post(`onboardings/${section}/done/`)
      return responseData
    } catch (error: any) {
      throw Error(error?.message)
    }
  },
}
