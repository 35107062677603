import {ReactComponent as Discord} from '../assets/icons/discord.svg'
import {ReactComponent as Facebook} from '../assets/icons/facebook.svg'
import {ReactComponent as Github} from '../assets/icons/github.svg'
import {ReactComponent as Instagram} from '../assets/icons/instagram.svg'
import {ReactComponent as Linkedin} from '../assets/icons/linkedin.svg'
import {ReactComponent as Telegram} from '../assets/icons/telegram.svg'
import {ReactComponent as Twitter} from '../assets/icons/twitter.svg'
// import {ReactComponent as Youtube} from '../assets/icons/youtube.svg'

export const socials = [
  {
    name: 'facebook',
    icon: <Facebook />,
    // url: 'https://www.facebook.com/KephiGallery/',
  },
  {
    name: 'twitter',
    icon: <Twitter />,
    url: 'https://twitter.com/KephiGallery',
  },
  {
    name: 'instragram',
    icon: <Instagram />,
    url: 'https://instagram.com/kephigallery?igshid=YmMyMTA2M2Y=',
  },
  // {
  //   name: 'youtube',
  //   icon: <Youtube />,
  //   url: 'https://www.youtube.com/channel/UCgQtaGud_JYKWPzu05NxLhA',
  // },
  {
    name: 'github',
    icon: <Github />,
    // url: 'https://github.com/orgs/kephi-gallery/',
  },
  {
    name: 'linkedin',
    icon: <Linkedin />,
    url: 'https://www.linkedin.com/company/kephi-gallery/',
  },
  {
    name: 'discord',
    icon: <Discord />,
    // url: 'https://discord.com/invite/YMgwGeWQ',
  },
  {
    name: 'telegram',
    icon: <Telegram />,
    // url: 'https://t.me/KephiGallery',
  },
]
