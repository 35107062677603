import {useCallback, useState} from 'react'
import classNames from 'classnames'
import {Autoplay, Pagination} from 'swiper'
import {Swiper, SwiperSlide} from 'swiper/react'

import 'swiper/scss'
import 'swiper/scss/pagination'
import 'swiper/scss/autoplay'
import 'swiper/scss/free-mode'

import {defaultSettings} from '../../constants/sliderSettings'

import styles from './Slider.module.scss'

interface ISwiperProps {
  className?: string
  customClasses?: {
    swiperSlide?: string
    slide?: string
    bullet?: string
    bulletActive?: string
  }
  slides: any[]
  renderSlide: (slide: any, slideClass: string) => JSX.Element
  showBullet?: boolean
  settings?: {
    speed: number
    slidestoshow: number
    spaceBetween: number
    autoplay?: boolean
    loop?: boolean
    rewind?: boolean
    breakpoints?: any
    direction?: 'vertical' | 'horizontal'
  }
  onActiveIndexChange?: (activeIndex: number) => void
}

const Slider = ({
  className,
  customClasses = {},
  slides,
  showBullet,
  renderSlide,
  settings = {
    speed: 500,
    slidestoshow: 1,
    spaceBetween: 20,
  },
  onActiveIndexChange,
}: ISwiperProps) => {
  const modules = [Pagination, Autoplay]
  const [activeIndex, setActiveIndex] = useState<number>(0)
  const onActiveSlideChange = useCallback(() => {
    if (!!onActiveIndexChange) {
      const active = activeIndex < (slides?.length || 1) - 1 ? activeIndex + 1 : 0
      setActiveIndex(active)
      onActiveIndexChange(active)
    }
  }, [slides, activeIndex, setActiveIndex])

  return (
    <Swiper
      slidesPerView={1}
      pagination={{
        horizontalClass: styles.horizontal,
        verticalClass: styles.vertical,
        clickable: true,
        type: 'bullets',
        dynamicBullets: true,
        dynamicMainBullets: slides.length,
        bulletClass: classNames(styles.bullet, customClasses.bullet, {[styles.active]: showBullet}),
        bulletActiveClass: customClasses.bulletActive,
      }}
      modules={modules}
      className={classNames(styles.swiper, className)}
      onActiveIndexChange={onActiveSlideChange}
      {...defaultSettings}
      {...settings}>
      {slides.map(slide => (
        <SwiperSlide
          key={slide?.id || slide?.name || slide?.title || slide}
          className={classNames(styles.swiperSlide, customClasses.swiperSlide)}>
          {renderSlide(slide, classNames(styles.slide, customClasses.slide))}
        </SwiperSlide>
      ))}
    </Swiper>
  )
}

export default Slider
