import {useCallback, useEffect, useState} from 'react'
import {WithTranslation, withTranslation} from 'react-i18next'
import {useParams} from 'react-router-dom'
import {Elements} from '@stripe/react-stripe-js'
import {Appearance, loadStripe} from '@stripe/stripe-js'
import {StripeElementsOptions} from '@stripe/stripe-js'

import BannerContainer from '../../../../../../containers/BannerContainer'
import BannerSection from '../../../../../../containers/BannerSection'
import {saleApi} from '../../../../../../services'
import {TOKEN_AMOUNT_INDEX, useCounterSlice} from '../../../../../../store/slices/counter'

import CheckoutForm from './CheckoutForm/CheckoutForm'

import styles from './Stripe.module.scss'

// secret
// pi_3N8YtyI3DxpZq8Uj0WT47YSZ_secret_mW50cMfq8u42kIItMgN9jSq6
// pk
// pk_test_51MrieYI3DxpZq8UjL0cBlqSNpHQPvXH17zdxItQOeTKU11Sd2hhaeRatbPH9SjbiZkBbHGXEbbWpGiatEHQrg9oC004OCskRYU

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.
const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_API_KEY ||
    'pk_test_51MrieYI3DxpZq8UjL0cBlqSNpHQPvXH17zdxItQOeTKU11Sd2hhaeRatbPH9SjbiZkBbHGXEbbWpGiatEHQrg9oC004OCskRYU',
)

const Stripe = ({t}: WithTranslation) => {
  const {itemId} = useParams()
  const [clientSecret, setClientSecret] = useState()
  // 'pi_3NB2gKI3DxpZq8Uj12XYgvV9_secret_cbV5DQZIlymfhukyR1e9ZD5Hp',

  const {quantity} = useCounterSlice()

  const status = new URLSearchParams(window.location.search).get('redirect_status')
  // console.log('status', status)

  const createPaymentIntent = useCallback(async () => {
    // console.log(`quantity: ${quantity[`${TOKEN_AMOUNT_INDEX}${itemId}`] || 1}, tokenId: ${itemId}`)
    const {
      data: {client_secret},
    } = await saleApi.createPaymentLink(
      quantity[`${TOKEN_AMOUNT_INDEX}${itemId}`] || 1,
      +(itemId || 0),
    )
    setClientSecret(client_secret)
  }, [clientSecret])

  useEffect(() => {
    if ((!!status && status === 'succeeded') || !!clientSecret) return
    createPaymentIntent()
  }, [])

  const appearance: Appearance = {
    theme: 'stripe',
  }
  const options: StripeElementsOptions = {
    clientSecret,
    appearance,
  }

  return (
    <BannerSection className={styles.section}>
      <BannerContainer className={styles.container} layout="column">
        <div className={styles.title}>{t('stripe.title')}</div>
        <div className={styles.stripe}>
          {clientSecret && (
            <Elements options={options} stripe={stripePromise}>
              <CheckoutForm />
            </Elements>
          )}
        </div>
      </BannerContainer>
    </BannerSection>
  )
}

export default withTranslation()(Stripe)
