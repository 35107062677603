import React, {createContext, useContext, useEffect, useMemo, useState} from 'react'
import Web3 from 'web3'

import {getWeb3} from '../libs/web3'

const Web3Context = createContext<any>([])

export const Web3ContextProvider = ({children}: any) => {
  const [web3, setWeb3] = useState<Web3>()

  const value = useMemo(
    () => ({
      web3,
      setWeb3,
    }),
    [web3, setWeb3],
  )

  useEffect(() => {
    getWeb3().then(setWeb3)
  }, [])

  return <Web3Context.Provider value={{...value}}>{children}</Web3Context.Provider>
}

export const useWeb3 = (): {
  web3: Web3
  setWeb3: React.Dispatch<Web3>
} => {
  return useContext(Web3Context)
}
