import Skeleton from 'react-loading-skeleton'
import classNames from 'classnames'

import useBreakpoint from '../../../hooks/useBreakpoint'
import ItemCardSkeleton from '../../ItemCard/skeleton/ItemCardSkeleton'

import styles from '../FeaturedItems.module.scss'

interface IFeaturedItemsSkeleton {
  design?: 'first' | 'second'
}
const FeaturedItemsSkeleton = ({design = 'first'}: IFeaturedItemsSkeleton) => {
  const breakpoint = useBreakpoint()
  return (
    <div className={classNames('secondary-background', styles.wrapContainer, styles[design])}>
      <div className={classNames(styles.textWrapper, styles[design])}>
        <div className={styles.title}>
          <Skeleton
            width="80%"
            height={35}
            count={breakpoint !== 's' && breakpoint !== 'xs' && design === 'first' ? 2 : 1}
          />
        </div>
        {design === 'first' && (
          <>
            <div className={styles.description}>
              <Skeleton
                width="70%"
                height={25}
                count={breakpoint !== 's' && breakpoint !== 'xs' ? 3 : 1}
              />
            </div>
            <div className={styles.border} />
            {breakpoint !== 's' && breakpoint !== 'xs' && (
              <div className={styles.footer}>
                <Skeleton width="80%" count={2} />
              </div>
            )}
          </>
        )}
      </div>
      <div className={classNames(styles.slider, styles[design])}>
        <ItemCardSkeleton layout="row" design={design} className={styles[design]} />
      </div>
    </div>
  )
}
export default FeaturedItemsSkeleton
