import axios from '../../core/axios'
import {BRAND, BRAND_KEY} from '../../utils/brand'
import {IProject, TypologyType} from '../interfaces/IProject'

export default {
  getProject: async (id: number): Promise<IProject> => {
    const data = await axios
      .get(`/projects/${id}${(BRAND !== BRAND_KEY.criptokuantica && '/?brand=' + BRAND) || ''}`)
      .then(({data}) => data)
      .catch(error => {
        throw error
      })
    return data
  },
  getProjects: async (): Promise<IProject[]> => {
    const {
      data: {results},
    } = await axios.get(
      `/projects/${(BRAND !== BRAND_KEY.criptokuantica && '?brand=' + BRAND) || ''}`,
    )
    return results
  },
  getOutstandingProjects: async (typology?: TypologyType | 'all'): Promise<IProject[]> => {
    const {
      data: {results},
    } = await axios.get(
      !typology || (!!typology && typology === 'all')
        ? `/projects/${(BRAND !== BRAND_KEY.criptokuantica && '?brand=' + BRAND) || ''}`
        : `/projects/?${
            (BRAND !== BRAND_KEY.criptokuantica && 'brand=' + BRAND) || ''
          }&typology=${typology.toLocaleUpperCase()}`,
    )
    return results.filter((project: IProject) => project.is_outstanding)
  },
}
